<template>
  <div id='qualityDisclose'>
    <div class="Implement-add">
      <div class="adds"
           @click="addfn(1)">添加交底</div>
           <div class="adds"
           @click="addtypefn(1)">添加交底类别</div>
    </div>

    <div class="Implement-wrap">
      <div class="Implement-title">
        <span class="title-01">序号</span>
        <span class="title-02">质量交底类别</span>
        <span class="title-03">质量交底名称</span>
        <span class="title-04">添加时间</span>
        <span class="title-05">操作</span>
      </div>

      <div class="Implement-list"
         v-if="listdata.length"
           v-for='(item,index) in listdata'
           :key="index">
        <span class="title-01 textOverflowHide">{{index+1}}</span>
        <span class="title-02 textOverflowHide">{{item.SecurityCategoryName}}</span>
        <span class="title-03 textOverflowHide">{{item.SecurityDisclosureName}}</span>
        <span class="title-04 textOverflowHide">
         {{item.CreateTime}}
        </span>
        <span class="title-05 textOverflowHide">
     
          <i @click="addfn(2,item)">编辑</i>
          <i @click="delfn(item)">删除</i>
        </span>
      </div>
      <div class="notdata" v-else>
        暂无数据
      </div>
    </div>
    <div class="pages"
         v-if="total && total > pageSizes">
      <el-pagination @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-size="pageSizes"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>

    <addDisclose v-if="addPfalse"
                  :sqtype='sqtype'
                 :addPtitle='addPtitle'
                 :editdata='editdata'
                 @addPfalsefn='addPfalsefn'
                 @GetSecurityDisclosureList='GetSecurityDisclosureList'></addDisclose>

    <addDtype v-if="addDfalse"
              :sqtype='sqtype'
            :addDtitle='addDtitle'
                 :editDdata='editDdata'
                 @addDfalsefn='addDfalsefn'
    ></addDtype>
  </div>

</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage,ElMessageBox } from "element-plus";
import addDisclose from '@/components/addDisclose.vue'
import addDtype from '@/components/addDtype.vue'
import {DeleteSecurityDisclosure,GetSecurityDisclosureList,GetSecurityDisclosure,GetQualityDisclosureList} from '@/js/safeDisclose'
import { getStorage } from "@/js/common";
import { baseUrl } from "@/js/util.js";
import {DelPictureList} from "@/js/indexApi.js";
export default {
  name: '',
  props: {

  },

  setup (props) {

    const state = reactive({
      baseUrl:baseUrl,
      total: 0,
      currentPage: 1,
      pageSizes: 10,
      addPfalse: false,
      addDfalse:false,
      addPtitle: '',
      addDtitle:'',
      listdata:[],
      editdata:'',
      sqtype:2,//安全：1，质量：2，
    });
    const cdata = getStorage("bscdata");
    const delDoc = (docs) => {
      // 删除文件
      DelPictureList(docs).then((res) => {
       
       
      });
    };
    const methods = {
      // 交底详情
      GetSecurityDisclosure:(item,id)=>{
        let datas={
         ID:item.ID
        }
        GetSecurityDisclosure(datas).then((res) => {
          // 添加工程图片
          console.log(res,'list');
          if (res.data.Code == 1) {
            if(id==1){
             
            }else if(id==2){
               state.editdata=res.data.Data[0];
               state.addPfalse = true
               console.log(state.editdata,12)
            }
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });

      },
      addfn: (id,item) => {
        console.log(121212)
        if(id==1){
           state.addPtitle = '添加交底';
           state.addPfalse = true
        }else if(id==2){
          state.addPtitle='编辑交底';
          methods.GetSecurityDisclosure(item,2)
         
          console.log(state.editdata,'state.editdata')
        }
       
        
      },
      // 添加类别
      addtypefn:(id,item)=>{
        if(id==1){
          state.addDtitle='添加交底类别'
        
        }else if(id==2){
          state.addDtitle='编辑交底类别';
          state.editDdata=item
        
          console.log(state.editdata,'state.editdata')
        }
        state.addDfalse = true
      },
      //添加弹框 关闭弹框
      addPfalsefn () {
        state.addPfalse = false;
      },
      // 添加类别
      addDfalsefn(){
        state.addDfalse = false;
      },
      // 列表获取分页数据
      handleCurrentChange: (val) => {
        state.currentPage = val;
        methods.GetSecurityDisclosureList()
      },

    // 列表
    
     GetSecurityDisclosureList:()=>{
        let datas={
          ProjectID:cdata.pid,
          pageIndex:state.currentPage,
          pageSize:state.pageSizes,
        }
        GetQualityDisclosureList(datas).then((res) => {
        
          console.log(res,'list');
          if (res.data.Code == 1) {
            state.listdata=res.data.Data.list;
            state.total=res.data.Data.total.Value;
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });

      },
      del(item){
         let datas={
          ID:item.ID,
        }
        DeleteSecurityDisclosure(datas).then((res) => {
          if (res.data.Code == 1) {
           ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "success",
            });
           let imgs=[]
            imgs.push(item.ImgUrl)
            delDoc(imgs)
            //  methods.GetSecurityDisclosure(item,1)
            methods.GetSecurityDisclosureList()
        
          } else {
            ElMessage({
              showClose: true,
              message: res.data.Message,
              type: "error",
            });
          }
        });
      },
        // 删
      delfn(item){

         ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
               methods.del(item)
          }).catch((error) => {
           

          })
       
       
       
      },



    };


    onMounted(() => {
          methods.GetSecurityDisclosureList()
    });

    return {
      ...methods,
      ...toRefs(state)
    };

  },

  components: {
    addDisclose,
    addDtype,
  }


}

</script>

<style lang='scss' scoped>
#qualityDisclose {
  margin: 0 30px;
  box-sizing: border-box;
  .Implement-add {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    box-sizing: border-box;
    .adds {
      // width: 66px;
      // height: 36px;
      padding: 9px 20px;
      box-sizing: border-box;

      background: #0083ff;
      border-radius: 5px;
      text-align: center;
    margin-left: 20px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  .Implement-title,
  .Implement-list {
    width: 100%;
    height: 40px;
    background: #e8f3f8;
    border: 1px solid #d1e2e5;
    font-size: 12px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #999999;
    display: inline-flex;
    align-items: center;
  }
  .Implement-list {
    background: transparent;
    height: 70px;
    line-height: 70px;
    span {
      i {
        &:nth-child(1) {
          color: #0083ff;
        }

        &:nth-child(2) {
          color: #e7111b;
        }
      }
    }
    .title-04 {
      display: flex;

    }
  }
  .Implement-list {
    border-top: none;
    overflow: hidden;
  }
  .Implement-title > span,
  .Implement-list > span {
    display: inline-block;
    line-height: 40px;
    padding-left: 1%;
    border-right: 1px solid #d1e2e5;
    box-sizing: border-box;
  }
  .Implement-list > span {
    line-height: 70px;
  }

  .title-01 {
    width: 4%;
  }
  .title-02 {
    width: 36%;
  }
  .title-03 {
    width: 36%;
  }
  .title-04 {
    width: 16%;
  }
  .title-05 {
    width: 8%;
  }



  .Implement-list > .title-05 {
    display: flex;
    padding: 0;
    justify-content: space-around;
    cursor: pointer;
  }

  .pages {
    margin-top: 20px;
    box-sizing: border-box;
    float: right;
  }
}
</style>